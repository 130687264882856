<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="margin-bottom: -40px"
            />
          </span>
          <!--h2 class="brand-text text-primary ml-1">Netciti</h2-->
        </b-link>

        <!--b-card-title class="mb-1">Welcome to Vuexy! 👋</b-card-title-->
        <b-card-text
          class="mb-2"
        >Please sign-in to your account and start the adventure</b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="submitLogin"
          >
            <!-- nik -->
            <b-form-group
              label-for="nik"
              label="NIK"
            >
              <validation-provider
                #default="{ errors }"
                name="NIK"
                rules="required"
              >
                <b-form-input
                  id="nik"
                  v-model="loginForm.nik"
                  name="login-nik"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nik"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="loginForm.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!--b-form-group>
              <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me</b-form-checkbox>
            </b-form-group-->

            <!-- submit button -->
            <loading v-if="isLoading" />
            <template v-else>
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="loginForm.invalid"
                @click="submitLogin"
              >Sign in</b-button>
            </template>
          </b-form>
        </validation-observer>

        <!--b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div-->

        <!-- social button -->
        <!--div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook"><feather-icon icon="FacebookIcon" /></b-button>
          <b-button href="javascript:void(0)" variant="twitter"><feather-icon icon="TwitterIcon" /></b-button>
          <b-button href="javascript:void(0)" variant="google"><feather-icon icon="MailIcon" /></b-button>
          <b-button href="javascript:void(0)" variant="github"><feather-icon icon="GithubIcon" /></b-button>
        </div-->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import interfaces from '@/templates/_interfaces.js'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Loading from '@core/loading/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    // BSV
    Loading,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      loginForm: interfaces.login,
      welcome_redirect: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    submitLogin() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          axios.post('authentication/login/', this.loginForm).then(response => {
            if (response.data.status === 2000) {
              localStorage.setItem('token', response.data.token)
              localStorage.setItem('nik', response.data.nik)
              localStorage.setItem('name', response.data.name)
              localStorage.setItem('department', response.data.department)
              localStorage.setItem('division', response.data.division)
              localStorage.setItem('level', response.data.level)
              localStorage.setItem('parent', response.data.parent)
              localStorage.setItem('id', response.data.id)
              localStorage.setItem('admin', response.data.admin)
              localStorage.setItem('view_only', response.data.view_only)
              localStorage.setItem('welcome_redirect', response.data.welcome_redirect)
              localStorage.setItem('employee_position_name', response.data.employee_position_name)
              localStorage.setItem('update_readyness', response.data.update_readyness)

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Login Success',
                  icon: 'EditIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.handleSubmit()
            } else {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Login Failed',
                  icon: 'EditIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login Failed',
                icon: 'EditIcon',
                variant: 'danger',
                text: error.message,
              },
            })
            localStorage.clear()
            this.$router.push({ name: 'login' })
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
    handleSubmit() {
      this.isLoading = false
      this.welcome_redirect = localStorage.getItem('welcome_redirect')
      if (this.welcome_redirect !== '') {
        this.$router.push({ name: this.welcome_redirect })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
